import React from "react";
import { Row, Col } from "antd";
import "./styles.less";

const Footer = () => {
  return (
    <footer className="page-footer">
      <div className="container core">
        <Row style={{ textAlign: "center", margin: "50px 0 0" }}>
          <Col xs={24}>©Lea Wormsbach {new Date().getFullYear()}</Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
